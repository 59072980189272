import {IConfigParser, ParserProps} from '@avanio/variable-util';
/**
 * // original function
 * const urlBase64ToUint8Array = (base64String: string) => {
 *	const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
 *	const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
 *
 *	const rawData = window.atob(base64);
 *	const outputArray = new Uint8Array(rawData.length);
 *
 * 	for (let i = 0; i < rawData.length; ++i) {
 *		 outputArray[i] = rawData.charCodeAt(i);
 * 	}
 *	return outputArray;
 * };
 */

export const base64Uint8ArrayParser: IConfigParser<Uint8Array, Uint8Array> = {
	name: 'base64Uint8ArrayParser',
	parse: ({value}: ParserProps): Uint8Array => {
		const padding = '='.repeat((4 - (value.length % 4)) % 4);
		const base64 = (value + padding).replace(/-/g, '+').replace(/_/g, '/');
		const rawData = window.atob(base64);
		const outputArray = new Uint8Array(rawData.length);

		for (let i = 0; i < rawData.length; ++i) {
			outputArray[i] = rawData.charCodeAt(i);
		}
		return outputArray;
	},
};
