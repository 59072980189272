import React from 'react';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {MsalProvider} from '@azure/msal-react';
import {getMsalInstance} from './lib/msal';
import {ServiceWorkerProvider} from './ServiceWorkerProvider';
import {listen} from './serviceWorkerRegistration';
import {HelmetProvider} from 'react-helmet-async';

function start() {
	return Promise.all([
		import('./configureStore' /* webpackChunkName: "configurestore", webpackPreload: true */),
		import('./i18n' /* webpackChunkName: "i18n", webpackPreload: true */),
		import('react-dom/client' /* webpackChunkName: "react-dom", webpackPreload: true */),
		import('react-redux' /* webpackChunkName: "redux", webpackPreload: true */),
		import('redux-persist/integration/react' /* webpackChunkName: "persist", webpackPreload: true */),
		import('react-i18next' /* webpackChunkName: "i18next", webpackPreload: true */),
		import('./App' /* webpackChunkName: "app", webpackPreload: true */),
		import('./reportWebVitals' /* webpackChunkName: "web-vitals", webpackPreload: true */),
		getMsalInstance(),
	]).then((loaded) => {
		const [configureStore, i18n, ReactDOM, Redux, Persist, I18next, AppModule, reportWebVitals, pca] = loaded;
		const {store, persistor} = configureStore;
		const App = AppModule.default;
		const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
		root.render(
			<React.StrictMode>
				<React.Suspense fallback={<div>Loading</div>}>
					<ServiceWorkerProvider listener={listen}>
						<Redux.Provider store={store}>
							<Persist.PersistGate loading={null} persistor={persistor}>
								<I18next.I18nextProvider i18n={i18n.default}>
									<MsalProvider instance={pca}>
										<HelmetProvider>
											<App />
										</HelmetProvider>
									</MsalProvider>
								</I18next.I18nextProvider>
							</Persist.PersistGate>
						</Redux.Provider>
					</ServiceWorkerProvider>
				</React.Suspense>
			</React.StrictMode>,
		);
		reportWebVitals.default();
	});
}
start();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
